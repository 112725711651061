<template>
  <Dialog @close="$emit('close')">
    <div class="w-72 bg-white p-6">
      <div v-if="type" class="flex justify-center text-[50px] mb-6 mt-6">
        <template v-if="type === 'warn'"> <SvgHomie class="w-24" /></template>
      </div>
      <div
        v-if="title"
        v-html="$nl2br(title)"
        class="text-center text-lg leading-8 font-semibold mt-2 break-keep"
      />
      <div
        v-html="$nl2br(message)"
        class="text-center text-gray-800 mt-6 break-keep"
        :class="{
          'text-sm': textSize === 'sm',
          'text-2sm': textSize === '2sm',
          'text-lg': textSize === 'lg',
          'text-xl': textSize === 'xl',
          'text-2xl': textSize === '2xl',
          'text-base': textSize === 'base',
        }"
      />
      <div class="mt-6">
        <template v-if="cancelText">
          <ZipupContainer
            v-if="buttonType === 'row'"
            :gap="'base'"
            :row="buttonType === 'row'"
          >
            <ZipupButton
              :size="'base'"
              :color="'default'"
              @click="$emit('cancel')"
              :title="cancelText"
            />
            <ZipupButton
              :size="'base'"
              :color="'prime'"
              @click="$emit('confirm')"
              :title="confirmText || '확인'"
            />
          </ZipupContainer>
          <ZipupContainer v-else :gap="'base'">
            <ZipupButton
              :size="'base'"
              :color="'prime'"
              @click="$emit('confirm')"
              :title="confirmText || '확인'"
            />
            <ZipupButton
              :size="'base'"
              :color="'default'"
              @click="$emit('cancel')"
              :title="cancelText"
            />
          </ZipupContainer>
        </template>
        <template v-else>
          <div class="flex">
            <ZipupButton
              :size="'base'"
              :color="buttonStyle"
              @click="$emit('confirm')"
              :title="confirmText || '확인'"
            />
          </div>
        </template>
      </div>
    </div>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    confirmText: {
      type: String,
      default: null,
    },
    cancelText: {
      type: String,
      default: null,
    },
    textSize: {
      type: String as () => 'base' | 'sm' | '2sm' | 'lg' | 'xl' | '2xl',
      default: 'base',
    },
    type: {
      type: String,
      default: null,
    },
    buttonType: {
      type: String as () => 'row' | 'column',
      default: 'row',
    },
    buttonStyle: {
      type: String,
      default: 'prime',
    },
  },
})
</script>
