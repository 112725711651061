import TypedLocalStore from 'typed-local-store'
import { SaleExplorePaginationFilterInput } from '~/types/graphql'

export interface TypedLocalStoreSchema {
  legacyUserAnnouncementPopup: boolean | null // 집업 1.0 사용자 대상으로 보여주는 공지 팝업 오픈이후 일주일 예상

  legacyFilter: SaleExplorePaginationFilterInput | null // 저장된 필터 내용
  filterSubway: string[] | null // 저장된 지하철역 필터 내용
  filterState: string | null
  initialLandingHome: boolean | null //앱에 첫 진입시 온보딩 내용을 보여주는 페이지

  initialLandingPlanner: boolean | null // 플래너에 첫 진입시 이사 예정일을 입력하게 하는 가이드를 보여줄지 여부 (이사 예정일이 없는 경우)
  initialSubwayQuestion: boolean | null // 매물에 첫 진입시 지하철역을 입력하는 시트를 보여줄지 여부

  lastLoginMethod: 'kakao' | 'google' | 'apple' | null // 마지막으로 로그인한 방법
  lastCategory: number | null // 마지막으로 로그인한 방법

  jeonseSagiInfo: any | null //전세사기 주소
  storeHubKoreaGuide1: boolean | null //스토어허브 가이드1

  jeonseSaftyReportAgree: boolean | null // 전세사기 동의
  hanacardPopupView: boolean | null // 하나카드 팝업 뷰
  membershipPopupView: boolean | null // 멤버십 팝업 뷰
  serviceHolidayPopupView: boolean | null // 서비스 휴무 팝업 뷰
}

export interface TypedSessionStoreSchema {
  hanacardPopupView: boolean | null // 하나카드 팝업 뷰
  benefitTitle: string | null // 혜택 이름
}

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      typedLocalStore: new TypedLocalStore<TypedLocalStoreSchema>(),
      typedSessionStore: new TypedLocalStore<TypedSessionStoreSchema>({
        storage: 'sessionStorage',
      }),
    },
  }
})
