import { Buffer } from 'buffer'

export enum LayoutType {
  Splash = 0,
  MainGuest = 1,
  Webview = 101,
}

export interface UploadedFile {
  id: string
  filename: string
  url: string
  mimeType: string
}

export interface UploadParams {
  accessToken: string | null
  limit?: number /* default = 1, max> = 10 */
  type?: 'image' | 'video' | null
  onSelect?: (count: number) => void
  onThumbnail?: (state: { body: string /* base64 */; index: number }) => void
  onProgress?: (state: {
    step: 'encoding' | 'upload' | 'error'
    progress: number /* erorr: 1 */
    index: number
  }) => void
}

export interface UploadError {
  // timeout - 앱에서 사용 안함
  // size - 파일 용량 초과
  // count - 파일 갯수 초과 (안드로이드 한정)
  reason: 'timeout' | 'size' | 'count' | 'unknown'
}

export interface NativeRequest {
  url: string
  options?: RequestInit
}

export interface NativeToastOptions {
  type: 'success' | 'error' | 'info'
  position: 'top' | 'bottom'
  text1: string
  text2: string
}

export interface paymentParams {
  clientKey: string
  customerKey: string
  price: number
  orderId: string
  orderName: string
}

export interface PaymentIAPParams {
  sku: string
}

export type PaymentV2Params =
  | {
      type: 'TOSS'
      data: TossPaymentParams
    }
  | {
      type: 'PAYPLE'
      data: PayplePaymentParams
    }
  | {
      type: 'SMARTRO'
      data: SmartroPaymentParams
    }

export interface TossPaymentParams {
  clientKey: string
  customerKey: string
  price: number
  orderId: string
  orderName: string
  type: 'toss'
}

export interface PayplePaymentParams {
  price: number
  uuid: string
  orderName: string
  type: 'payple'
}

export interface SmartroPaymentParams {
  price: number
  uuid: string
  orderName: string
  ediDate: string
  encryptData: string
  taxAmt: number
  taxFreeAmt: number
  vatAmt: number
  type: 'smartro'
}

export interface VueNative {
  device(): Promise<string>
  enabled(): boolean
  ready(): Promise<void>
  log(log: string): void
  alert(message: string): void
  share(link: string): void
  upload(params: UploadParams): Promise<(UploadedFile | null)[]>
  changeAppLayout(type: LayoutType, withAnimation?: boolean): void
  clearHistory(link: string): void
  restart(): void
  version(): Promise<string>
  token(): Promise<string>
  request(data: NativeRequest): Promise<string>
  checkSaleValidation(url: string): Promise<boolean>
  notificationPermission(): Promise<boolean>
  requestPermission(name: string): Promise<void>
  allPermissions(): Promise<any>
  openSettings(): void

  persistCookies(): void // only android
  forceUpdate(): Promise<boolean>
  isRecentVersion(): Promise<boolean>
  initProducts(productIds: string[]): Promise<any>
  requestPurchase(prodcutId: string): Promise<any>

  kakaoOauth(): Promise<KakaoOauth | null>
  googleOauth(): Promise<GoogleOauth | null>
  appleOauth(): Promise<AppleOauth | null>

  keyboardHeight(): Promise<string>
  toast(options: NativeToastOptions): void
  payment(params: paymentParams): void
  paymentv2(params: PaymentV2Params): void
  paymentIAP(params: PaymentIAPParams): Promise<any>
  closePayment(params: { redirect: string }): Promise<any>
}

export interface NativeGlobal {
  __move(link: string): void
  __action(action: string): void
  __setDeviceState(
    deviceType: string,
    deviceToken: string,
    appVersion: string,
  ): void
  __webkitCallback(cid: number, result: any): void
  __push(url: string, data: any): void
}

interface InternalSendMessage {
  handler: string
  args: any[]
  cid?: number
  errorCid?: number
}

function defer<T>(): {
  promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason?: any) => void
} {
  let resolveToReturn: (value: T | PromiseLike<T>) => void = () => {}
  let rejectToReturn: (reason?: any) => void = () => {}
  const promise = new Promise<T>((resolve, reject) => {
    resolveToReturn = resolve
    rejectToReturn = reject
  })
  return { promise, resolve: resolveToReturn, reject: rejectToReturn }
}

let _callIndex = 0
const _callbacks = new Map<number, (result: any) => any>()

function createCallback<T, TError = {}>(
  name: string,
  timeout?: number,
): { cid: number; errorCid: number; promise: Promise<T>; clear: () => void } {
  const { promise, resolve, reject } = defer<T>()
  let promiseToReturn = promise

  const cid = _callIndex++
  const errorCid = _callIndex++
  const clear = () => {
    _callbacks.delete(cid)
    _callbacks.delete(errorCid)
  }

  if (typeof timeout === 'number' && timeout >= 0) {
    const timer = setTimeout(() => {
      const rejectFn = _callbacks.get(errorCid)
      if (rejectFn) {
        rejectFn(Object.assign(new Error('timeout'), { reason: 'timeout' }))
      }
    }, timeout)
    promiseToReturn = promise.finally(() => {
      clearTimeout(timer)
    })
  }

  const start = Date.now()
  _callbacks.set(cid, (result: T) => {
    _callbacks.delete(cid)
    _callbacks.delete(errorCid)
    console.log(`[invoke_cb:${name}] resolve ${Date.now() - start}ms`)
    resolve(result)
  })
  _callbacks.set(errorCid, (error: TError) => {
    _callbacks.delete(cid)
    _callbacks.delete(errorCid)
    console.log(`[invoke_cb:${name}] reject ${Date.now() - start}ms`)
    reject(error)
  })

  return {
    cid,
    errorCid,
    promise: promiseToReturn,
    clear,
  }
}

function createHandler<T>(
  name: string,
  handler: (value: T) => any,
): { cid: number; clear: () => void } {
  const cid = _callIndex++
  const clear = () => {
    _callbacks.delete(cid)
  }

  _callbacks.set(cid, (result: T) => {
    console.log(`[invoke_handle:${name}] ${new Date()}`)
    handler(result)
  })

  return {
    cid,
    clear,
  }
}

export function compareVersions(a: string, b: string): number {
  const aParts = a.split('.').map(Number)
  const bParts = b.split('.').map(Number)
  for (let i = 0; i < aParts.length && i < bParts.length; i++) {
    if (aParts[i] > bParts[i]) return 1
    if (aParts[i] < bParts[i]) return -1
  }
  return aParts.length - bParts.length
}

const statusBarStyleLightPages = new Set([
  'app-campaigns-id',
  'app-campaigns-id-receipt',
])

export default defineNuxtPlugin((nuxtApp) => {
  const ctx = useNuxtApp()
  const config = useRuntimeConfig()

  if (process.client) {
    const nativeGlobal: NativeGlobal = {
      __move(link: string) {
        if (process.client) {
          postMessage({
            handler: 'log',
            args: [
              `[__move] ${JSON.stringify({
                link,
                route: ctx.$router.currentRoute.value.fullPath,
              })}`,
            ],
          })
        }
      },
      __action(action: string) {
        postMessage({
          handler: 'log',
          args: [
            `[__action] ${JSON.stringify({
              route: ctx.$router.currentRoute.value.fullPath,
            })}`,
          ],
        })
      },
      __setDeviceState(
        deviceType: string,
        deviceToken: string,
        appVersion: string,
      ) {
        if (!deviceType || !appVersion) {
          return
        }

        return
      },
      __webkitCallback(cid: number, result: any) {
        const callback = _callbacks.get(cid)
        if (callback) {
          callback(result)
        }
      },
      __push(url: string, data: any) {
        postMessage({
          handler: 'log',
          args: [
            `[__move] ${JSON.stringify({
              url,
              route: ctx.$router.currentRoute.value.fullPath,
            })}`,
          ],
        })
        ctx.$router.push({ path: url, query: data })
      },
    }
    Object.assign(window, nativeGlobal)
  }

  function postMessage(message: InternalSendMessage, only?: 'ios' | 'android') {
    if (process.server) {
      return
    }
    if ((window as any).ReactNativeWebView) {
      ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(message))
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.hu &&
      only !== 'android'
    ) {
      ;(window as any)?.ReactNativeWebView?.postMessage(message)
      return
    }
    if (window.android && only !== 'ios') {
      ;(window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(message))
      return
    }
    new Error('no native') // eslint-disable-line
  }

  function invoke<T>(
    handler: string,
    args: any = [],
    timeout = 5000,
  ): Promise<T> {
    const { promise, cid, errorCid } = createCallback<T>(handler, timeout)
    postMessage({ handler, args, cid, errorCid })
    return promise
  }

  function detectReactNative() {
    if (process.client) {
      return !!(window as any).ReactNativeWebView
    }
    return false
  }

  const native: VueNative = {
    device(): Promise<string> {
      return invoke<string>('device')
    },
    enabled() {
      return detectReactNative()
    },
    ready(): Promise<void> {
      return invoke<void>('ready')
    },
    log(message: string): void {
      postMessage({ handler: 'log', args: [message] })
    },
    alert(message: string) {
      postMessage({ handler: 'alert', args: [message] })
    },
    share(link: string): void {
      postMessage({ handler: 'share', args: [link] })
    },
    upload(params: UploadParams): Promise<(UploadedFile | null)[]> {
      const clearFns = [] as (() => void)[]

      let selectCid = null as number | null
      let thumbnailCid = null as number | null
      let progressCid = null as number | null

      if (params.onSelect) {
        const { cid, clear } = createHandler<number>(
          'upload__select',
          params.onSelect,
        )
        selectCid = cid
        clearFns.push(clear)
      }
      if (params.onThumbnail) {
        const { cid, clear } = createHandler<{
          body: string
          index: number
        }>('upload__thumb', params.onThumbnail)
        thumbnailCid = cid
        clearFns.push(clear)
      }
      if (params.onProgress) {
        const { cid, clear } = createHandler<{
          step: 'encoding' | 'upload'
          progress: number
          index: number
        }>('upload__thumbnail', params.onProgress)
        progressCid = cid
        clearFns.push(clear)
      }

      const { promise, cid, errorCid } =
        createCallback<(UploadedFile | null)[]>('upload')
      postMessage({
        handler: 'upload',
        args: [
          selectCid,
          thumbnailCid,
          progressCid,
          params.accessToken,
          Math.min(Math.max(params.limit ?? 1, 1), 10), // 무조건 1~10 사이로..
          params.type ?? null,
        ],
        cid,
        errorCid,
      })

      return promise.then((result) => {
        clearFns.forEach((fn) => fn())
        return result
      })
    },
    changeAppLayout(type: LayoutType, withAnimation: boolean = false) {
      postMessage({ handler: 'changeAppLayout', args: [type, withAnimation] })
    },
    clearHistory(link: string): void {
      postMessage({ handler: 'clearHistory', args: [link] })
    },
    restart(): void {
      postMessage({ handler: 'restart', args: [] })
    },
    version(): Promise<string> {
      return invoke<string>('version')
    },
    token(): Promise<string> {
      return invoke<string>('token')
    },
    // only android
    persistCookies(): void {
      postMessage({ handler: 'persistCookies', args: [] }, 'android')
    },
    async forceUpdate(): Promise<boolean> {
      const device = await this.device()
      const version = await this.version()

      if (config.public.NODE_ENV !== 'production') {
        return false
      }

      if (device === 'iOS') {
        if (compareVersions(version, '2.27.2') < 0) {
          return true
        }
      }

      if (device === 'Android') {
        if (compareVersions(version, '2.4.4') < 0) {
          return true
        }
      }

      return false
    },
    request(data: NativeRequest) {
      return invoke('request', [data])
    },
    async checkSaleValidation(url: string) {
      const options = {
        headers: {
          'User-Agent':
            'Mozilla/5.0 (Linux; Android 11; SM-G991N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.61 Mobile Safari/537.36',
        },
      }

      try {
        if (/realty.daum.net/.test(url)) {
          // 직방
          const match = /\d+/.exec(url)
          if (!match) {
            return false
          }
          const code = match[0]

          const response = await this.request({
            url: `https://apis.zigbang.com/v3/items/${code}?version=&domain=zigbang`,
            options,
          })
          const text = Buffer.from(response, 'base64').toString('utf-8')
          const json = JSON.parse(text)

          if (json?.code === 404) {
            return false
          }
          return true
        } else if (/dabang/.test(url)) {
          // 다방
          const splited = url.split('/')
          const code = splited.pop()

          const response = await this.request({
            url: `https://www.dabangapp.com/api/3/new-room/detail?api_version=3.0.1&call_type=web&room_id=${code}&version=1`,
            options,
          })
          const text = Buffer.from(response, 'base64').toString('utf-8')
          const json = JSON.parse(text)
          this.log(`[native] dabang ${JSON.stringify(json)}`)

          if (
            json?.msg === '존재하지 않는 매물입니다.' ||
            json?.room?.status !== 1
          ) {
            return false
          }
          return true
        } else if (/realestate.hankyung.com/.test(url)) {
          // 한경
          const match = /\d+/.exec(url)
          if (!match) {
            return false
          }

          const code = match[0]
          const response = await this.request({
            url: `https://realestate.hankyung.com/api/v1/listings/${code}/_user_view`,
            options,
          })
          const text = Buffer.from(response, 'base64').toString('utf-8')
          const json = JSON.parse(text)

          if (json?.errors?.error) {
            return false
          }
          return true
        }
      } catch (e) {
        this.log(`${e}`)
      }
      return false
    },
    async notificationPermission(): Promise<boolean> {
      const isRecentVersion = await this.isRecentVersion()

      if (!isRecentVersion) {
        return true
      }

      return invoke<string>('notificationPermission').then((v) => v === 'true')
    },
    async requestPermission(name: string): Promise<void> {
      const isRecentVersion = await this.isRecentVersion()

      if (!isRecentVersion) {
        return
      }

      return invoke<void>('requestPermission', [name])
    },
    async allPermissions(): Promise<any> {
      const isRecentVersion = await this.isRecentVersion()

      if (!isRecentVersion) {
        return JSON.stringify('{}')
      }

      return invoke<any>('allPermissions')
    },
    openSettings(): void {
      this.isRecentVersion().then((v) => {
        if (!v) {
          return
        }
        postMessage({ handler: 'openSettings', args: [] })
      })
    },

    async isRecentVersion(): Promise<boolean> {
      const device = await this.device()
      const version = await this.version()

      let isLower = true
      if (device === 'iOS') {
        if (compareVersions(version, '2.24.6') < 0) {
          return false
        }
      }

      if (device === 'Android') {
        if (compareVersions(version, '1.16.1') < 0) {
          return false
        }
      }

      return isLower
    },
    initProducts(productIds: string[]) {
      return invoke('initProducts', productIds)
    },
    requestPurchase(prodcutId: string) {
      return invoke('requestPurchase', prodcutId, 60000)
    },

    async kakaoOauth() {
      console.log(`[native] kakaoOauth start`)
      this.log(`[native] kakaoOauth start`)
      try {
        return invoke<string>('kakaoOauth', null, -1).then((v) => {
          return JSON.parse(v)
        })
      } catch (e) {
        this.log(`[native] kakaoOauth parse fail ${e}`)
        return null
      }
    },
    async googleOauth() {
      this.log(`[native] google start`)
      try {
        return invoke<string>('googleOauth', null, -1).then((v) => {
          return JSON.parse(v)
        })
      } catch (e) {
        return null
      }
    },
    async appleOauth() {
      try {
        return invoke<string>('appleOauth', null, -1).then((v) => {
          this.log(`[native] appleOauth ${v}`)
          return JSON.parse(v)
        })
      } catch (e) {
        this.log(`[native] appleOauth parse fail ${e}`)
        return null
      }
    },
    keyboardHeight() {
      return invoke<string>('keyboardHeight')
    },
    toast(options: NativeToastOptions) {
      postMessage({ handler: 'toast', args: [options] })
    },

    payment(params: paymentParams) {
      console.log(params)
      postMessage({ handler: 'payment', args: [params] })
    },
    paymentv2(params: PaymentV2Params) {
      postMessage({ handler: 'paymentv2', args: [params] })
    },
    async paymentIAP(params: PaymentIAPParams) {
      try {
        return invoke('paymentIAP', [params], 240000)
      } catch (e) {
        return null
      }
    },
    async closePayment(params: { redirect: string }) {
      try {
        return invoke<string>('closePayment', [params])
      } catch (e) {
        console.log(e)
      }
    },
  }

  nuxtApp.provide('native', native)
})
