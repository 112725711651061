export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('to', to)
  console.log('from', from)
  // if (process.client) {
  //   const app = useNuxtApp()

  //   console.log('페이지접근')
  //   console.log('from.fullPath', from.fullPath)
  // }
})
